import { defineNuxtPlugin } from 'nuxt/app';
// import { useI18n } from 'vue-i18n';
import serialize from '@/plugins/common/serialize';

export default defineNuxtPlugin((nuxtApp: any) => {
    //const { tm } = useI18n();
    const home = () => '/';
    const about = () => '/about';
    const article = (item: any) => `/articles/${item.id}/${item.slug}`;
    const plugin = (item: any) => `/plugins/${item.id}/${item.slug}`;
    const card = (item: any) => {
        if (!item.is_article) {
            return article(item);
        }
        if (item.is_package) {
            return plugin(item);
        }
        return '/';
    };
    const group = (item: any) => {
        if (item === null) {
            return '/';
        }
        return `/${item.slug}`;
    };
    const page = (item: any) => `/${item.slug}`;
    const groupCategory = (item: any, item2: any) => `/${item.slug}/${item2.slug}`;
    const authLogin = () => '/auth/login';
    const personal = () => '/personal';
    const authRegister = () => '/auth/register';
    const modelEditor = (model: string, item: any, locale: any, request: any) => {
        let url = '';
        if (item !== undefined && item !== null) {
            url = `/${model}/${item.id}/editor`;
        } else {
            url = `/${model}/editor`;
        }
        const query = {};
        if (request !== undefined) {
            Object.keys(request).forEach((key) => {
                query[key] = request[key];
            });
        }
        if (locale !== undefined && locale !== null && query.locale === undefined) {
            query.locale = locale;
        }
        return url + '?' + serialize.query(query);
    };
    return {
        home,
        about,
        personal,
        modelEditor,
        authLogin,
        authRegister,
        group,
        groupCategory,
        article,
        plugin,
        card,
        page,
    };
});

import { defineNuxtPlugin } from 'nuxt/app';

export default defineNuxtPlugin((nuxtApp) => {
    // console.log('error-handler.ts');
    // nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    //     // handle error, e.g. report to a service
    //     console.log('error');
    // };
    //
    // // Also possible
    // nuxtApp.hook('vue:error', (error, instance, info) => {
    //     // handle error, e.g. report to a service
    //     console.log('vue:error');
    // });
    // // Also possible
    // nuxtApp.hook('app:error', (error, instance, info) => {
    //     // handle error, e.g. report to a service
    //     console.log('app:error');
    // });
});

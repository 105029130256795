import { default as indexcUkHgaFVZEMeta } from "/var/www/vuejsexamples/subdomain-laravel/src/pages/[slug]/[slug2]/[slug3]/index.vue?macro=true";
import { default as indexKbfsSBjs7iMeta } from "/var/www/vuejsexamples/subdomain-laravel/src/pages/[slug]/[slug2]/index.vue?macro=true";
import { default as indexITsXUyQV7KMeta } from "/var/www/vuejsexamples/subdomain-laravel/src/pages/[slug]/index.vue?macro=true";
import { default as _91slug_93sHCK1i0qXgMeta } from "/var/www/vuejsexamples/subdomain-laravel/src/pages/articles/[id]/[slug].vue?macro=true";
import { default as indexQVX6caTIK2Meta } from "/var/www/vuejsexamples/subdomain-laravel/src/pages/articles/[id]/index.vue?macro=true";
import { default as indexKKhj1673Q4Meta } from "/var/www/vuejsexamples/subdomain-laravel/src/pages/index.vue?macro=true";
import { default as pagesOu6v6tPjKDMeta } from "/var/www/vuejsexamples/subdomain-laravel/src/pages/pages.vue?macro=true";
import { default as searchtBhd3twpGlMeta } from "/var/www/vuejsexamples/subdomain-laravel/src/pages/search.vue?macro=true";
import { default as _91slug_93aWJSrPVwx5Meta } from "/var/www/vuejsexamples/subdomain-laravel/src/pages/tags/[slug].vue?macro=true";
export default [
  {
    name: indexcUkHgaFVZEMeta?.name ?? "slug-slug2-slug3",
    path: indexcUkHgaFVZEMeta?.path ?? "/:slug()/:slug2()/:slug3()",
    meta: indexcUkHgaFVZEMeta || {},
    alias: indexcUkHgaFVZEMeta?.alias || [],
    redirect: indexcUkHgaFVZEMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-laravel/src/pages/[slug]/[slug2]/[slug3]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKbfsSBjs7iMeta?.name ?? "slug-slug2",
    path: indexKbfsSBjs7iMeta?.path ?? "/:slug()/:slug2()",
    meta: indexKbfsSBjs7iMeta || {},
    alias: indexKbfsSBjs7iMeta?.alias || [],
    redirect: indexKbfsSBjs7iMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-laravel/src/pages/[slug]/[slug2]/index.vue").then(m => m.default || m)
  },
  {
    name: indexITsXUyQV7KMeta?.name ?? "slug",
    path: indexITsXUyQV7KMeta?.path ?? "/:slug()",
    meta: indexITsXUyQV7KMeta || {},
    alias: indexITsXUyQV7KMeta?.alias || [],
    redirect: indexITsXUyQV7KMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-laravel/src/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sHCK1i0qXgMeta?.name ?? "articles-id-slug",
    path: _91slug_93sHCK1i0qXgMeta?.path ?? "/articles/:id()/:slug()",
    meta: _91slug_93sHCK1i0qXgMeta || {},
    alias: _91slug_93sHCK1i0qXgMeta?.alias || [],
    redirect: _91slug_93sHCK1i0qXgMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-laravel/src/pages/articles/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexQVX6caTIK2Meta?.name ?? "articles-id",
    path: indexQVX6caTIK2Meta?.path ?? "/articles/:id()",
    meta: indexQVX6caTIK2Meta || {},
    alias: indexQVX6caTIK2Meta?.alias || [],
    redirect: indexQVX6caTIK2Meta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-laravel/src/pages/articles/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKKhj1673Q4Meta?.name ?? "index",
    path: indexKKhj1673Q4Meta?.path ?? "/",
    meta: indexKKhj1673Q4Meta || {},
    alias: indexKKhj1673Q4Meta?.alias || [],
    redirect: indexKKhj1673Q4Meta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-laravel/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: pagesOu6v6tPjKDMeta?.name ?? "pages",
    path: pagesOu6v6tPjKDMeta?.path ?? "/pages",
    meta: pagesOu6v6tPjKDMeta || {},
    alias: pagesOu6v6tPjKDMeta?.alias || [],
    redirect: pagesOu6v6tPjKDMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-laravel/src/pages/pages.vue").then(m => m.default || m)
  },
  {
    name: searchtBhd3twpGlMeta?.name ?? "search",
    path: searchtBhd3twpGlMeta?.path ?? "/search",
    meta: searchtBhd3twpGlMeta || {},
    alias: searchtBhd3twpGlMeta?.alias || [],
    redirect: searchtBhd3twpGlMeta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-laravel/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aWJSrPVwx5Meta?.name ?? "tags-slug",
    path: _91slug_93aWJSrPVwx5Meta?.path ?? "/tags/:slug()",
    meta: _91slug_93aWJSrPVwx5Meta || {},
    alias: _91slug_93aWJSrPVwx5Meta?.alias || [],
    redirect: _91slug_93aWJSrPVwx5Meta?.redirect || undefined,
    component: () => import("/var/www/vuejsexamples/subdomain-laravel/src/pages/tags/[slug].vue").then(m => m.default || m)
  }
]
// import fs from 'fs';
import { defineAppConfig } from '#app/nuxt';

export default defineAppConfig({
    foo: 'bar81',
    logging: {
        version: false,
        theme: false,
        axios: false,
    },
    // config,
});

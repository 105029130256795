import { defineNuxtPlugin } from 'nuxt/app';
import { plugin } from 'vue-tippy';

export default defineNuxtPlugin((nuxtApp: any) => {
    // nuxtApp.vueApp.directive('tippy', directive);
    nuxtApp.vueApp.use(plugin, {
        defaultProps: {
            arrow: false,
            directive: 'tippy',
        },
    });
    // console.log('sticky', Sticky);
    nuxtApp.vueApp.directive('sticky', Sticky);
});

/**
 * Vue Sticky directive
 * @directive
 * @example
 * {
 *   directives: {
 *     Sticky,
 *   },
 *   template: `<div v-sticky="{ offset: 0, isSticky: true }"></div>`,
 * }
 */
const Sticky = {
    mounted(el, binding, vnode) {
        console.log(el, binding.value);
        setStyle(el, binding.value);
    },
    bind(el, binding) {
        console.log(el, binding.value);
        setStyle(el, binding.value);
    },
    componentUpdated(el, binding) {
        console.log(el, binding.value);
        setStyle(el, binding.value);
    },
    getSSRProps(binding: any, vnode: any) {
        return {};
    }
};

const setStyle = (el, values) => {
    const defaults = {
        isSticky: true,
        offset: 54,
    };

    const isSticky = values && values.isSticky !== undefined
        ? values.isSticky
        : defaults.isSticky;

    if (isSticky) {
        const offset = values && (values.offset || defaults.offset);

        el.style.top = `${offset}px`;
        el.style.position = 'sticky';
        el.style.position = '-webkit-sticky'; /* Safari */
        el.classList.remove('h-100');
    } else {
        el.style.cssText = null;
        el.classList.add('h-100');
    }
};

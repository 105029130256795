import { useRoute } from 'vue-router';
import useI18nRoute from '@/hooks/i18nRoute';
import { useStoreAppBreadcrumbsIndex } from '@/stores/app/breadcrumbs';

class Breadcrumbs {
    route: any;
    data: any;
    breadcrumbs: any;
    i18nRoute: any;

    constructor() {
        this.route = useRoute();
        this.breadcrumbs = useStoreAppBreadcrumbsIndex();
        this.i18nRoute = useI18nRoute();
    }

    init() {
        this.data = [];
    }

    create() {
        this.breadcrumbs.setData([]);
        this.breadcrumbs.setByItems(this.route.name, this.data);
    }

    getTitle() {
        return this.breadcrumbs.getTitle;
    }

    getData() {
        return this.data;
    }

    pushGroup(item) {
        this.data.push({
            url: this.i18nRoute.group(item),
            title: item !== null ? item.title : '-',
        });
    }

    pushTag(item) {
        this.data.push({
            url: this.i18nRoute.tag(item),
            title: item !== null ? `#${item.slug}` : '-',
        });
    }

    pushPage(item) {
        if (item.section !== null) {
            this.data.push({
                url: this.i18nRoute.page(item.section),
                title: item.section.title,
            });
        }
        this.data.push({
            url: this.i18nRoute.page(item),
            title: item.title,
        });
    }

    pushError(data) {
        this.data.push({
            url: this.i18nRoute.page({
                slug: '/error'
            }),
            title: data.title,
        });
    }

    pushGroupCategory(group, item) {
        this.data.push({
            url: this.i18nRoute.groupCategory(group, item),
            title: item.title,
        });
    }

    pushGroupCategoryChildren(group, item) {
        this.data.push({
            url: this.i18nRoute.groupCategory(group, item),
            title: item.title,
        });
    }

    pushArticle(item) {
        this.data.push({
            url: this.i18nRoute.article(item),
            title: item.title,
        });
    }
}
export {
    Breadcrumbs,
};

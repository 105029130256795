import { Directives } from "bootstrap-vue-next";
import { defineNuxtPlugin, useRuntimeConfig } from "#imports";
import {directive} from "vue-tippy";

export default defineNuxtPlugin((nuxtApp) => {
    //const usedDirectives = useRuntimeConfig().public.bootstrapVueNext.directives;
    const usedDirectives = [
        'vBModal',
    ];
    const directiveValues = Object.entries(Directives);
    //console.log(directiveValues);
    const result = directiveValues.reduce(
    (acc, [key, value]) => {
        if (usedDirectives.includes(key)) {
            acc[key] = value;
        }
        return acc;
    }, {});
    const sliceName = (str) => str.toLowerCase().startsWith("v") ? str.slice(1) : str;
    //console.log(result);
    Object.entries(result).forEach(([key, value]) => {
        const parsedName = sliceName(key);
        //console.log(parsedName, value);
        nuxtApp.vueApp.directive(parsedName, value);
    });

});

import * as Sentry from '@sentry/vue';
import { defineNuxtPlugin } from 'nuxt/app';
import { useRouter } from 'vue-router';

const SENTRY_DNS = import.meta.env.VITE_SENTRY_DNS;
const SENTRY_ENABLED = import.meta.env.VITE_SENTRY_ENABLED;
const SENTRY_ENV = import.meta.env.VITE_SENTRY_ENV;

export default defineNuxtPlugin((nuxtApp: any) => {
    const router = useRouter();
    // const { public: { sentry } } = useRuntimeConfig();
    const sentry = {
        enabled: SENTRY_ENABLED === 'true',
        dsn: SENTRY_DNS,
        environment: SENTRY_ENV,
    };
    if (!sentry.enabled) {
        return;
    }
    if (!sentry.dsn) {
        return;
    }

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: sentry.dsn,
        environment: sentry.environment,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],

        // Configure this whole part as you need it!

        tracesSampleRate: 0.2, // Change in prod

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'https://your-server.com'],

        replaysSessionSampleRate: 1.0, // Change in prod
        replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
    });
});

import { defineNuxtPlugin, useCookie } from 'nuxt/app';
import crpyto from './common/crpyto';

export default defineNuxtPlugin((nuxtApp: any) => {
    nuxtApp.getDataConfig = () => {
        const cookie = useCookie('data:config');
        return cookie.value;
    };
    nuxtApp.setDataConfig = (data: any) => {
        const cookie = useCookie('data:config');
        cookie.value = data;
    };
    // Функция для установки cookie
    nuxtApp.provide('setCookie', (name, value, options) => {
        if (process.server) {
            // На стороне сервера: использование HTTP заголовков для установки cookies
            const { res } = nuxtApp.ssrContext.event;
            const cookieOptions = options || {};
            let cookieString = `${name}=${value}; Path=${cookieOptions.path || '/'};`;
            if (cookieOptions.maxAge) {
                cookieString += ` Max-Age=${cookieOptions.maxAge};`;
            }
            // Добавление secure и httpOnly, если это необходимо
            if (cookieOptions.secure) {
                cookieString += ' Secure;';
            }
            if (cookieOptions.httpOnly) {
                cookieString += ' HttpOnly;';
            }
            res.setHeader('Set-Cookie', cookieString);
        } else {
            //value = crpyto.encrypt(value);
            // На стороне клиента: использование document.cookie для установки cookies
            const cookieOptions = options || {};
            let cookieString = `${name}=${value}; path=${cookieOptions.path || '/'};`;
            if (cookieOptions.maxAge) {
                const date = new Date();
                date.setTime(date.getTime() + (cookieOptions.maxAge * 1000));
                cookieString += ` expires=${date.toUTCString()};`;
            }
            if (cookieOptions.secure) {
                cookieString += ' Secure;';
            }
            if (cookieOptions.httpOnly) {
                console.warn('HttpOnly cookies can only be set on the server side');
            }
            document.cookie = cookieString;
        }
    });
    nuxtApp.provide('getCookie', (name) => {
        let result = null;
        if (process.server) {
            // На стороне сервера: чтение из заголовков запроса
            const { req } = nuxtApp.ssrContext.event;
            const cookies = req.headers.cookie;
            if (cookies) {
                const cookiesArray = cookies.split('; ');
                const cookie = cookiesArray.find(c => c.startsWith(name + '='));
                if (cookie) {
                    result = cookie.split('=')[1];
                }
            }
        } else {
            // На стороне клиента: использование document.cookie
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) {
                result = parts.pop().split(';').shift();
            }
            if (result !== null) {
                //result = crpyto.decrypt(result);
            }
        }
        return result;
    });
    nuxtApp.provide('deleteCookie', (name) => {
        if (process.server) {
            //
        } else {
            document.cookie = `${name}=; Max-Age=0; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        }
    });

    // Функция для получения значения cookie
    // Здесь может быть реализация функции для чтения cookies, аналогично установке
});
// export default function useAppCookies() {
//     const getDataConfig = () => {
//         const cookie = useCookie('data:config');
//         return cookie.value;
//     };
//     const setDataConfig = (data: any) => {
//         const cookie = useCookie('data:config');
//         cookie.value = data;
//     };
//     return {
//         setDataConfig,
//         getDataConfig,
//     };
// }

<template>
    <ol v-if="breadcrumbs.data.length" class="breadcrumb pr--breadcrumb">
        <li v-for="(value, key) in breadcrumbs.data" :key="key" :class="`breadcrumb-item ${value.active ? 'active' : ''}`">
            <NuxtLink v-if="!value.active" :to="value.to">
                {{ value.text }}
            </NuxtLink>
            <span v-if="value.active">
                {{ value.text }} {{ value.count !== null ? ` (Всего ${value.count})` : '' }}
            </span>
        </li>
    </ol>
</template>

<script>
import { defineComponent } from 'vue';
import { useStoreAppBreadcrumbsIndex } from '@/stores/app/breadcrumbs';

export default defineComponent({
    name: 'BaseBreadcrumbs',
    components: {
        //
    },
    setup() {
        const breadcrumbs = useStoreAppBreadcrumbsIndex();
        return {
            breadcrumbs,
        };
    },
});
</script>

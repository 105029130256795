export const appHead = {"meta":[{"charset":"utf-16"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":"Laravel - это платформа веб-приложений с выразительным и элегантным синтаксисом. Мы уже заложили основу - предоставив вам свободу творить, не беспокоясь о мелочах."},{}],"link":[{"rel":"stylesheet","href":"/css/preload.css"},{"rel":"icon","href":"https://laravel.itlibs.ru/favicon.ico","type":"image/x-icon"}],"style":[],"script":[{},{},{}],"noscript":[],"charset":"utf-16","viewport":"width=device-width, initial-scale=1","title":"Laravel | IT Libs"}

export const appBaseUrl = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnUrl = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'
import moment from 'moment';

export default {
    query(params, prefix) {
        const self = this;
        const query = [];
        Object.keys(params).forEach(function (key) {
            let value = params[key];
            if (params.constructor === Array) {
                key = `${prefix}[]`;
            } else if (params.constructor === Object) {
                key = prefix !== undefined ? `${prefix}[${key}]` : key;
            } else if (params.constructor === Boolean) {
                value = value ? 1 : 0;
            }
            if (prefix !== undefined && prefix.endsWith('_rg')) {
                value = moment(value).format('YYYY-MM-DD');
            }
            if (value === null) {
                query.push(`${key}=null`);
                return true;
            }
            if (typeof value === 'object') {
                const data = self.query(value, key);
                data.forEach(function (val) {
                    query.push(val);
                });
            } else {
                const encodeValue = encodeURIComponent(value);
                query.push(`${key}=${encodeValue}`);
            }
        });
        //
        //
        // const query1 = Object.keys(params).map((key, number) => {
        //     console.log('key', key, prefix);
        //     let value = params[key];
        //     if (params.constructor === Array) {
        //         key = `${prefix}[]`;
        //     } else if (params.constructor === Object) {
        //         key = (prefix !== undefined ? `${prefix}[${key}]` : key);
        //     } else if (params.constructor === Boolean) {
        //         value = value ? 1 : 0;
        //     }
        //     if (prefix !== undefined && prefix.endsWith('_rg')) {
        //         value = moment(value).format('YYYY-MM-DD');
        //     }
        //     if (value === null) {
        //         return '';
        //     }
        //     if (typeof value === 'object') {
        //         console.log('object', key, self.query(value, key));
        //         return self.query(value, key);
        //     } else {
        //         let encodeValue = encodeURIComponent(value);
        //         return `${key}=${encodeValue}`;
        //     }
        // });
        if (prefix !== undefined) {
            return query;
        }
        const d = [];
        return d.concat.apply([], query).join('&');
    },
    urlToForm(params) {
        const value = {};
        const form = Object.assign({}, params);
        // eslint-disable-next-line array-callback-return
        Object.keys(form).map((key) => {
            const val = form[key];
            if (key.endsWith('[]')) {
                value[key.replace('[]', '')] = val.constructor === Array ? val : [val];
            } else {
                value[key] = val;
            }
        });
        return value;
    },
    requestNormalize(params) {
        const value = {};
        const form = Object.assign({}, params);
        console.log('form', form);
        // eslint-disable-next-line array-callback-return
        Object.keys(form).map((key) => {
            if (key.includes('[') && key.lastIndexOf(']') !== -1) {
                const newKey1 = key.substr(0, key.indexOf('['));
                const newKey2 = key.substr(
                    key.indexOf('[') + 1,
                    key.length - key.indexOf('[') - 2,
                );
                // 'meta_tags[title]'.substr('meta_tags[title]'.indexOf('[') + 1, 'meta_tags[title]'.length - 'meta_tags[title]'.indexOf('[') - 2)
                // '[meta_tags][title] = meta_tags[title]'
                if (value[newKey1] === undefined || value[newKey1] === null) {
                    value[newKey1] = {};
                }
                value[newKey1][newKey2] = form[key];
                return true;
            }
            if (form[key] !== null && form[key].constructor === Array) {
                if (form[key][0] !== undefined && form[key][0].constructor === Object) {
                    const values = [];
                    form[key].forEach((item) => {
                        values.push(item.id);
                    });
                    form[key] = values;
                }
            }
            if (form[key] !== null && form[key].constructor === Object) {
                if (form[key].id !== undefined) {
                    form[key] = form[key].id;
                } else {
                    // eslint-disable-next-line no-self-assign
                    form[key] = form[key];
                }
            }
            // console.log(form[key]);
            if (form[key] !== null) {
                if (form[key].constructor === Array) {
                    //
                } else if (form[key] === '') {
                    //
                } else {
                    value[key] = form[key];
                }
            }
        });
        return value;
    },
    panelForm(params) {
        const value = {};
        const form = Object.assign({}, params);
        console.log('form', form);
        // eslint-disable-next-line array-callback-return
        Object.keys(form).map((key) => {
            if (key.includes('[') && key.lastIndexOf(']') !== -1) {
                const newKey1 = key.substr(0, key.indexOf('['));
                const newKey2 = key.substr(
                    key.indexOf('[') + 1,
                    key.length - key.indexOf('[') - 2,
                );
                // 'meta_tags[title]'.substr('meta_tags[title]'.indexOf('[') + 1, 'meta_tags[title]'.length - 'meta_tags[title]'.indexOf('[') - 2)
                // '[meta_tags][title] = meta_tags[title]'
                if (value[newKey1] === undefined || value[newKey1] === null) {
                    value[newKey1] = {};
                }
                value[newKey1][newKey2] = form[key];
                return true;
            }
            const values = [];
            if (
                form[key] !== undefined &&
        form[key] !== null &&
        form[key].constructor === Object
            ) {
                if (form[key].id !== undefined) {
                    form[key] = form[key].id;
                } else if (form[key].blob !== undefined) {
                    form[key] = form[key].blob;
                } else {
                    form[key] = null;
                }
            }
            if (
                form[key] !== undefined &&
        form[key] !== null &&
        form[key].constructor === Array
            ) {
                form[key].forEach((item) => {
                    if (item.constructor === Object && item.id !== undefined) {
                        values.push(item.id);
                    }
                });
                if (values.length) {
                    form[key] = values;
                }
            }
            if (form[key] !== undefined && form[key] !== null) {
                value[key] = form[key];
            }
            if (value[key] === undefined && form[key] === null) {
                value[key] = null;
            }
        });
        console.log(value);
        return value;
    },
    editorForm(params) {
        const value = {};
        const form = Object.assign({}, params);
        // eslint-disable-next-line array-callback-return
        Object.keys(form).map((key) => {
            const values = [];
            if (key === 'blocks') {
                value[key] = form[key];
                return true;
            }
            if (
                form[key] !== undefined &&
        form[key] !== null &&
        form[key].constructor === Object
            ) {
                if (form[key].id !== undefined) {
                    form[key] = form[key].id;
                } else {
                    form[key] = null;
                }
            }
            if (
                form[key] !== undefined &&
        form[key] !== null &&
        form[key].constructor === Array
            ) {
                form[key].forEach((item) => {
                    if (item.constructor === Object && item.id !== undefined) {
                        values.push(item.id);
                    }
                });
                if (values.length) {
                    form[key] = values;
                }
            }
            if (form[key] !== undefined && form[key] !== null) {
                value[key] = form[key];
            }
            if (value[key] === undefined && form[key] === null) {
                value[key] = null;
            }
        });
        return value;
    },
    panelFormByFields(params, tabs) {
        const form = Object.assign({}, params);
        tabs.forEach(function (tab) {
            if (tab.data !== undefined) {
                tab.data.forEach(function (field) {
                    if (
                        form[field.key] !== undefined &&
            field.type === 'phone' &&
            form[field.key] !== null
                    ) {
                        form[field.key] = parseInt(
                            form[field.key].toString().replace(/[^0-9]/g, ''),
                        );
                    }
                    if (
                        form[field.key] !== undefined &&
            field.type === 'date' &&
            form[field.key] !== null
                    ) {
                        // form[field.key] = moment(form[field.key]).format('DD.MM.YYYY');
                    }
                });
            }
        });
        return form;
    },
    prioritySort(items, item, currentKey, value) {
        const values = [];
        items.forEach((x) => {
            if (x.id === item.id) {
                return true;
            }
            values.push(x.id);
        });
        if (value === -1) {
            values.splice(currentKey + 1, 0, item.id);
        } else {
            values.splice(currentKey - 1, 0, item.id);
        }
        return values;
    },
};

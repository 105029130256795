import { defineStore, acceptHMRUpdate } from 'pinia';
import { Breadcrumbs } from '@/hooks/Breadcrumbs';
import useI18nRoute from '@/hooks/i18nRoute';

interface State {
    pending: boolean;
    data: any[];
    values: any[];
}

export const useStoreAppBreadcrumbsIndex = defineStore({
    id: 'app/breadcrumbs/index',

    state: (): State => ({
        pending: true,
        data: [],
        values: [],
    }),

    getters: {
        getTitle: (state) => {
            let title = [];
            state.data.forEach((item) => {
                if (item.type === 'index') {
                    return true;
                }
                if (item.type === 'article') {
                    title = [];
                }
                title.push(item.text);
            });
            return title.reverse().join(' / ');
        },
    },

    actions: {
        setData(data: any) {
            this.data = data;
        },
        create(breadcrumbs: any) {
            const data = [];
            breadcrumbs.forEach((breadcrumb, key) => {
                if (breadcrumb.type === 'page') {
                    data.push({
                        type: 'page',
                        url: useI18nRoute().page(breadcrumb.data),
                        title: breadcrumb.data.title,
                    });
                }
                if (breadcrumb.type === 'tag') {
                    data.push({
                        type: 'tag',
                        url: useI18nRoute().tag(breadcrumb.data),
                        title: `#${breadcrumb.data.title}`,
                        count: breadcrumb.count,
                    });
                }
                if (breadcrumb.type === 'group') {
                    data.push({
                        type: 'group',
                        url: useI18nRoute().group(breadcrumb.data),
                        title: breadcrumb.data.title,
                    });
                }
                if (breadcrumb.type === 'group_category') {
                    data.push({
                        type: 'group_category',
                        url: useI18nRoute().group(breadcrumb.data),
                        title: breadcrumb.data.title,
                    });
                }
            });
            this.setByItems('create', data);
        },
        createArticle(article: any, group: any) {
            const data = [];
            data.push({
                type: 'group',
                url: useI18nRoute().group(group),
                title: group !== null ? group.title : '-',
            });
            data.push({
                type: 'article',
                url: useI18nRoute().article(article),
                title: article.title,
            });
            this.setByItems('article', data);
        },
        createGroup(group: any) {
            const data = [];
            data.push({
                type: 'group',
                url: useI18nRoute().group(group),
                title: group !== null ? group.title : '-',
                count: null,
            });
            this.setByItems('group', data);
        },
        createGroupCategory(group: any, groupCategory: any) {
            const data = [];
            data.push({
                type: 'group_category',
                url: useI18nRoute().group(group),
                title: group !== null ? group.title : '-',
            });
            // data.push({
            //     url: useI18nRoute().groupCategory(group, groupCategory),
            //     title: groupCategory.title,
            // });
            this.setByItems('group-category', data);
        },
        createPage(group: any) {
            const data = [];
            data.push({
                type: 'page',
                url: useI18nRoute().page(group),
                title: group !== null ? group.title : '-',
            });
            this.setByItems('page', data);
        },
        pushData(value: []) {
            this.data.push(value);
        },
        setEmptyData() {},
        setByItems(routeName: string, items: [{title: any, url: string, type: string, count: number|undefined}]) {
            const values = [];
            const index = {
                text: 'Главная',
                type: 'index',
                to: '/',
                disabled: false,
                active: false,
                count: null,
            };
            if (routeName === 'index' || routeName === 'index___ru') {
                index.active = true;
                values.push(index);
                this.setData(values);
                return;
            }
            values.push(index);
            items.forEach((item, key) => {
                let title = item.title;
                // if (item.count !== undefined) {
                //     title += ` (Всего ${item.count})`;
                // }
                values.push({
                    text: title,
                    type: item.type,
                    active: key === items.length - 1,
                    to: item.url,
                    disabled: false,
                    count: item.count !== undefined ? item.count : null,
                });
            });
            this.setData(values);
        },
    },
});
if (import.meta.hot) {
    import.meta.hot.accept(
        acceptHMRUpdate(useStoreAppBreadcrumbsIndex, import.meta.hot),
    );
}

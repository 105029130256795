<template>
    <NuxtLayout>
        <div class="container">
            <div class="row pr-bg-white pr-shadow-sm">
                <div class="col-12">
                    <div class="d-flex align-items-center justify-content-between pr--breadcrumb-container p-0">
                        <BaseBreadcrumbs />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="row mt-3 mb-3 rounded-sm-bottom pr-bg-white">
                        <div class="col-12 pr-shadow-sm p-3 rounded-2">
                            <h2>Ошибка {{ error.statusCode }}</h2>
                            <p class="mb-0">
                                {{ error.message }}
                            </p>
                            <!--                            <div class="row pr&#45;&#45;article&#45;&#45;details">-->
                            <!--                                <ClientOnly>-->
                            <!--                                    <EditorPreviewDefault-->
                            <!--                                        :item="null"-->
                            <!--                                        :blocks="[{-->
                            <!--                                            data: {-->
                            <!--                                                code: error.stack,-->
                            <!--                                                language: 'javascript',-->
                            <!--                                            },-->
                            <!--                                            id: '',-->
                            <!--                                            locale: 'ru',-->
                            <!--                                            priority: 0,-->
                            <!--                                            status: 1,-->
                            <!--                                            type: 'code'-->
                            <!--                                        }]"-->
                            <!--                                        :check-focused="false"-->
                            <!--                                        :prepend="[]"-->
                            <!--                                        :prepends="[]"-->
                            <!--                                    />-->
                            <!--                                </ClientOnly>-->
                            <!--                            </div>-->
                            <div v-if="VITE_APP_DEBUG" class="mt-3" v-html="error.stack" />
                            <div v-if="VITE_APP_DEBUG">
                                {{ error }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </NuxtLayout>
</template>

<script setup lang="ts">
import { useTitle } from '@vueuse/core';
import { onMounted } from 'vue';
import EditorPreviewDefault from '@/components/Editor/Preview/EditorPreviewDefault.vue';
import { Breadcrumbs } from '@/hooks/Breadcrumbs';
import type { NuxtError } from '#app';
import { definePageMeta } from '#imports';

const VITE_APP_DEBUG = import.meta.env.VITE_APP_DEBUG === 'true';
console.log(VITE_APP_DEBUG);

const breadcrumbs = (new Breadcrumbs());
useTitle('Frontend Главная');

const props = defineProps({
    error: Object as () => NuxtError
});

useHead({
    title: props.error.statusCode + ': ' + props.error.message,
});
onMounted(() => {
    breadcrumbs.init();
    breadcrumbs.pushError({
        title: props.error.statusCode + ': ' + props.error.message,
    });
    breadcrumbs.create();
});

</script>

import validate from "/var/www/vuejsexamples/subdomain-laravel/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/var/www/vuejsexamples/subdomain-laravel/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  data: () => import("/var/www/vuejsexamples/subdomain-laravel/src/middleware/data.ts"),
  developer: () => import("/var/www/vuejsexamples/subdomain-laravel/src/middleware/developer.ts"),
  "route-resolver": () => import("/var/www/vuejsexamples/subdomain-laravel/src/middleware/routeResolver.ts")
}
import { config, library, dom } from '@fortawesome/fontawesome-svg-core';
import {
    FontAwesomeIcon,
} from '@fortawesome/vue-fontawesome';
import { defineNuxtPlugin } from 'nuxt/app';
import {
    faClose,
    faPlus,
    faHome,
    faFile,
    faQuestionCircle,
    faBan,
    faEllipsisH,
    faComments,
    faEye,
    faEyeSlash,
    faHistory,
    faPencilAlt,
    faTrash,
    faUser,
    faLink,
    faUserCog,
    faCheck,
    faExclamationCircle,
    faUsers,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faSearch,
    faTools,
    faDownload,
    faFileUpload,
    faFilePdf,
    faBold,
    faItalic,
    faQuoteRight,
    faCode,
    faListUl,
    faListOl,
    faTasks,
    faTable,
    faFileImage,
    faCog,
    faBell,
    faExternalLink,
    faChevronUp,
    faSave,
    faSyncAlt,
    faGears,
    faCogs,
    faCircle,
    faCheckDouble,
    faVideo,
    faImage,
    faEllipsisV,
    faBars,
    faSitemap,
    faShareNodes,
    faCertificate,
    faBook,
    faRu,
    faCircleQuestion,
    farCircleQuestion,
    faTags,
    faLayerGroup,
    faCopy,
    faUserGear,
    faUserSecret,
    //
    faVk,
    faYandex,
    faGoogle,
    faFacebookF,
    faYandexInternational,
    faTelegram,
    //
    faClock,
    faExclamation,
    faLanguage,
    //
    faHeart,
    farHeart,
    faThumbsDown,
    faThumbsUp,
    faReply,
    faComment,
    faFire,
    faCircleUp,
} from './icons/free-solid-svg-icons';

// import { faSitemap } from '@fortawesome/free-solid-svg-icons';
// import { faSitemap } from '@fortawesome/free-solid-svg-icons';
// import { faSitemap } from '@fortawesome/free-regular-svg-icons';
// import { faYandex } from '@fortawesome/free-brands-svg-icons';
/* import font awesome icon component */

config.autoAddCss = false;

dom.watch();

export default defineNuxtPlugin((nuxtApp) => {
    /* add icons to the library */
    library.add(
        faVk,
        faYandex,
        faGoogle,
        faFacebookF,
        faYandexInternational,
        faTelegram,
        //
        faLanguage,
        faExclamation,
        faClock,
        faUserSecret,
        faUserGear,
        faCopy,
        faLayerGroup,
        faTags,
        farCircleQuestion,
        faCircleQuestion,
        faRu,
        faBook,
        faCertificate,
        faShareNodes,
        faSitemap,
        faClose,
        faFile,
        faHome,
        faQuestionCircle,
        faBan,
        faEllipsisH,
        faComments,
        faEye,
        faHistory,
        faPencilAlt,
        faTrash,
        faUser,
        faLink,
        faPlus,
        faUserCog,
        faCheck,
        faUsers,
        faChevronLeft,
        faChevronRight,
        faChevronDown,
        faSearch,
        faTools,
        faDownload,
        faFileUpload,
        faFilePdf,
        faBold,
        faItalic,
        faQuoteRight,
        faCode,
        faListUl,
        faListOl,
        faTasks,
        faTable,
        faFileImage,
        faCog,
        faBell,
        faExternalLink,
        faEyeSlash,
        faChevronUp,
        faSave,
        faSyncAlt,
        faExclamationCircle,
        faGears,
        faCogs,
        faCircle,
        faCheckDouble,
        faVideo,
        faImage,
        faEllipsisV,
        faBars,
        //
        farHeart,
        faHeart,
        faThumbsDown,
        faThumbsUp,
        faReply,
        faComment,
        faFire,
        faCircleUp,
    );
    nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon);
});

/* add font awesome icon component */
// export default FontAwesomeIcon;
